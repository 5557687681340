body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  min-height: 99vh;
  position: relative;
  padding-bottom: 45px;
  background-color: #ebecf2 !important;
  font-size: 14px;
}

code {
  font-family: "Lato", sans-serif !important;
}

.pointer {
  cursor: pointer;
}

.transform {
  text-transform: capitalize;
}

.font12Red {
  font-size: 12px;
  color: #d35151;
}

.font28Black {
  font-size: 28px;
  color: black !important;
}

.font26Black {
  font-size: 26px;
  color: black !important;
}

.font22Black {
  font-size: 22px;
  color: black !important;
}

.font24Black {
  font-size: 24px;
  color: black !important;
}

.font20Black {
  font-size: 20px;
  color: black !important;
}

.font18Black {
  font-size: 18px;
  color: black !important;
}

.font19Black {
  font-size: 19px;
  color: black !important;
}

.font15Black {
  font-size: 15px !important;
  color: black !important;
}

.font16Black {
  font-size: 16px;
  color: black !important;
}

.font14Black {
  font-size: 14px;
  color: black !important;
}

.font12Black {
  font-size: 12px;
  color: black !important;
}

.font12Green {
  font-size: 12px;
  color: #27D639 !important;
}

.boldFont {
  font-weight: bold;
}

.font20Blue {
  font-size: 20px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font22Blue {
  font-size: 22px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font18Blue {
  font-size: 18px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font16Blue {
  font-size: 16px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font15Blue {
  font-size: 15px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font14Blue {
  font-size: 14px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font12Blue {
  font-size: 12px;
  color: #3cb4e5 !important;
  cursor: pointer;
}

.font12Blue:hover,
.font14Blue:hover,
.font16Blue:hover,
.font18Blue:hover,
.font20Blue:hover,
.font14DarkBlue:hover,
.font15Blue:hover {
  color: #008fc9 !important;
}

.font19Grey {
  font-size: 19px;
  color: #666666 !important;
}

.font14Grey {
  font-size: 14px;
  color: #666666 !important;
}

.font14White {
  font-size: 14px !important;
  color: #ffffff !important;
}

.font15Grey {
  font-size: 15px;
  color: #666666 !important;
}

.font12Grey {
  font-size: 12px;
  color: #666666 !important;
}

.font14DarkBlue {
  color: #008fc9;
  font-size: 14px;
  cursor: pointer;
}

.tabWrappStyle {
  background-color: #dde4ee;
  display: flex;
  border-radius: 5px;
  height: 41px;
  align-items: center;
  width: fit-content;
}

.inactiveTabStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0em;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  cursor: pointer;
  color: #008fc9;
  white-space: nowrap;
  min-width: 100px;
  justify-content: center;
}

.disabled {
  color: #666666 !important;
}

.activeTabStyle {
  background: #ffffff;
  border-radius: 5px;
  min-width: 100px;
  justify-content: center;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  margin: 3px 5px;
  white-space: nowrap;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.25) !important;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    max-width: 100%;
    margin-top: 0px;
  }
}

.fw700 {
  font-weight: 700 !important;
}

.font15White {
  color: white;
  font-size: 15px;
}

.font12White {
  color: white;
  font-size: 12px;
}

.font12LightGrey {
  color: #B6B6B6;
  font-size: 12px;
}

a {
  text-decoration: none !important;
}

.optionCard {
  width: 152px;
  background-color: white;
  position: absolute;
  right: 0px;
  padding-top: 10px;
  box-shadow: 0px 0px 16px rgba(10, 38, 186, 0.12);
  border-radius: 4px;
  z-index: 8;
  min-width: fit-content;
  padding-left: 5px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    right: 0px;
    left: inherit
  }
}

.option {
  padding-bottom: 10px;
  color: #3cb4e5;
  font-size: 14px;
  margin-left: 5px;
  white-space: nowrap;
}

.optionIcon {
  height: 18px;
  margin-right: 4px;
}

.eyeIcon {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.ellipsis {
  background-color: #f5f5f5;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
}

.ellipsis:hover,
.ellipsis-grid:hover,
.ellipsisWrappper:hover {
  box-shadow: 0px 0px 8px rgba(10, 38, 186, 0.12);
}

.noWrap {
  white-space: nowrap;
}

.noRecord {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.folderIcon {
  margin-left: auto;
  display: block;
  margin-right: auto;
  padding-top: 20px;
  height: 111px;
  margin-top: 20px;
}

.uploadProgressBar {
  width: 100%;
  height: 6px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progressBarPercent {
  height: 6px;
  border-radius: 10px;
  background-color: #27D639;
}

.noHeadSeperator thead th {
  vertical-align: bottom;
  border-bottom: 0.75px solid #D6D7D9 !important;
  width: -1px !important;
  font-size: 14px !important;
  color: #666666;
  opacity: 0.8;
  font-weight: bold !important;
  padding: 0.5rem
}

.headSeperator thead th {
  vertical-align: bottom;
  border-bottom: 0.75px solid #d6d7d9 !important;
  border-top: 1px solid #d6d7d9 !important;
  width: -1px !important;
  font-size: 14px !important;
  color: #666666;
  opacity: 0.8;
  font-weight: bold !important;
  padding: 0.5rem;
}

.headSeperator tbody tr:last-child {
  vertical-align: bottom;
  border-bottom: 0.75px solid #d6d7d9 !important;
  width: -1px !important;
  font-size: 14px !important;
  color: #666666;
  font-weight: bold !important;
  padding: 0.5rem;
}

.table td {
  vertical-align: top !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #666666 !important;
  white-space: nowrap;
}

.table td :nth-last-child(2) {
  white-space: nowrap;
}

.table-hover tbody tr:hover {
  background: rgba(60, 180, 229, 0.15) !important;
}

.previewcard {
  overflow-wrap: anywhere;
}

.relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.errorText {
  font-size: 12px;
  line-height: 18px;
  color: #d35151;
}

.fitContent {
  width: fit-content;
}

.op5 {
  opacity: 0.5;
}

.ml75px {
  margin-left: 75px;
}

.zIndex9999 {
  z-index: 99999 !important;
}

.activeView:hover,
.inactiveView:hover {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3) !important;
}

.activeView {
  background: #3cb4e5;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 7px;
  border-radius: 4px;
  cursor: pointer;
}

.inactiveView {
  background: rgba(227, 231, 236, 1);
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 7px;
  border-radius: 4px;
}

.imgHolder {
  display: flex;
  justify-content: flex-end;
  background: rgba(227, 231, 236, 1);
  border-radius: 4px;
  cursor: pointer;
}

.documentPaginator {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-left: 10px !important;
    padding-top: 0px;
    display: inherit;
  }
}

.mb100px {
  margin-bottom: 100px;
}